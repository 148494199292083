import { combineReducers } from "redux";
import editorialReducer from "./editorialReducer";
import membershipReducer from "./membershipReducer";
import authReducer from "./authReducer";
import advisoryReducer from "./advisoryReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import executiveReducer from "./executiveReducer";
import scopeReducer from "./scopeReducer";
import batchReducer from "./batchReducer";
import litfestReducer from "./litfestReducer";
import submissionReducer from "./submissionReducer";
import litfestPostReducer from "./litfestPostReducer";
import eventReducer from "./eventReducer";
import eventpostReducer from "./eventpostReducer";
import journalReducer from "./journalReducer";
import newsReducer from "./newsReducer";
import contentReducer from "./contentReducer";
export default combineReducers({
  editorial: editorialReducer,
  auth: authReducer,
  member: membershipReducer,
  advisory: advisoryReducer,
  subscriptions: subscriptionsReducer,
  executive: executiveReducer,
  scope: scopeReducer,
  batch: batchReducer,
  submission: submissionReducer,
  litfest: litfestReducer,
  litfestpost: litfestPostReducer,
  events: eventReducer,
  eventpost: eventpostReducer,
  journal: journalReducer,
  news: newsReducer,
  content: contentReducer
});
