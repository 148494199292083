export default (state = null, action) => {
  switch (action.type) {
    case "AUTH_SUCCESS":
      return 1;
    case "AUTH_FAIL":
      return 0;
    default:
      return state;
  }
};
