import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Navbar from "./components/Navbar";
const Home = React.lazy(() => import("./components/Home"));
const Contact = React.lazy(() => import("./components/Contact"));
const ContactMIELS = React.lazy(() => import("./components/MIELS/Contact"));
const Scope = React.lazy(() => import("./components/Scope"));
const EditorialBoard = React.lazy(() => import("./components/EditorialBoard"));
const Master = React.lazy(() => import("./components/master/Master"));
const AuthorGuidelines = React.lazy(() =>
  import("./components/AuthorGuidelines")
);
const OnlineSubmission = React.lazy(() =>
  import("./components/OnlineSubmission")
);

const EditorialBoardMaster = React.lazy(() =>
  import("./components/master/EditorialBoard")
);
const LitFestMaster = React.lazy(() => import("./components/master/LitFest"));
const ContentMaster = React.lazy(() => import("./components/master/Content"));
const LitFestPostMaster = React.lazy(() =>
  import("./components/master/LitFestPost")
);
const ExecutiveMaster = React.lazy(() =>
  import("./components/master/Executive")
);
const SubscriptionsMaster = React.lazy(() =>
  import("./components/master/Subscriptions")
);
const MembershipsMaster = React.lazy(() =>
  import("./components/master/Memberships")
);
const AdvisoryBoardMaster = React.lazy(() =>
  import("./components/master/AdvisoryBoard")
);
const SubmissionMaster = React.lazy(() =>
  import("./components/master/Submission")
);
const EventsMaster = React.lazy(() => import("./components/master/Events"));
const JournalMaster = React.lazy(() => import("./components/master/Journal"));
const EventPostMaster = React.lazy(() =>
  import("./components/master/EventPost")
);
const NewsMaster = React.lazy(() => import("./components/master/News"));
const AdvisoryBoard = React.lazy(() => import("./components/AdvisoryBoard"));
const Subscriptions = React.lazy(() => import("./components/Subscriptions"));
const Archive = React.lazy(() => import("./components/Archive"));
const Content = React.lazy(() => import("./components/Content"));
const About = React.lazy(() => import("./components/MIELS/About"));
const LitFest = React.lazy(() => import("./components/MIELS/LitFest"));
const LitFestPost = React.lazy(() => import("./components/MIELS/LitFestPost"));
const EventPost = React.lazy(() => import("./components/MIELS/EventPost"));
const Events = React.lazy(() => import("./components/MIELS/Events"));
const OfficeBearers = React.lazy(() =>
  import("./components/MIELS/OfficeBearers")
);
const Memberships = React.lazy(() => import("./components/MIELS/Memberships"));
const Executive = React.lazy(() => import("./components/MIELS/Executive"));
const OnlineApplication = React.lazy(() =>
  import("./components/MIELS/OnlineApplication")
);

const NavbarContainer = ({ children }) => {
  return <Navbar>{children}</Navbar>;
};
const App = (props) => {
  return (
    <React.Fragment>
      <Router>
        <div>
          <NavbarContainer>
            <Suspense
              fallback={
                <div
                  style={{
                    marginTop: 300,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              }
            >
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/contact-us" exact component={Contact} />
                <Route path="/master" exact component={Master} />
                <Route
                  path="/master/journal/:id"
                  exact
                  component={ContentMaster}
                />
                <Route path="/scope" exact component={Scope} />
                <Route path="/miels/litfest" exact component={LitFest} />
                <Route
                  path="/miels/litfest/:id"
                  exact
                  component={LitFestPost}
                />
                <Route path="/miels/events/:id" exact component={EventPost} />
                <Route path="/miels/events" exact component={Events} />
                <Route path="/miels/contact" exact component={ContactMIELS} />
                <Route path="/archive" exact component={Archive} />
                <Route path="/archive/:id" exact component={Content} />
                <Route
                  path="/online-submission"
                  exact
                  component={OnlineSubmission}
                />
                <Route path="/subscriptions" exact component={Subscriptions} />
                <Route
                  path="/editorial-board"
                  exact
                  component={EditorialBoard}
                />
                <Route path="/advisory-board" exact component={AdvisoryBoard} />
                <Route
                  path="/author-guidelines"
                  exact
                  component={AuthorGuidelines}
                />
                <Route path="/miels/about" exact component={About} />
                <Route
                  path="/miels/executive-members"
                  exact
                  component={Executive}
                />
                <Route
                  path="/miels/office-bearers"
                  exact
                  component={OfficeBearers}
                />
                <Route
                  path="/master/editorial-board"
                  exact
                  component={EditorialBoardMaster}
                />
                <Route path="/master/journal" exact component={JournalMaster} />
                <Route path="/master/news" exact component={NewsMaster} />
                <Route path="/master/litfest" exact component={LitFestMaster} />
                <Route
                  path="/master/litfest/:id"
                  exact
                  component={LitFestPostMaster}
                />
                <Route path="/master/events" exact component={EventsMaster} />
                <Route
                  path="/master/events/:id"
                  exact
                  component={EventPostMaster}
                />
                <Route
                  path="/master/litfest/:id"
                  exact
                  component={LitFestPostMaster}
                />
                <Route
                  path="/master/advisory-board"
                  exact
                  component={AdvisoryBoardMaster}
                />
                <Route
                  path="/master/submission"
                  exact
                  component={SubmissionMaster}
                />
                <Route
                  path="/master/memberships"
                  exact
                  component={MembershipsMaster}
                />
                <Route
                  path="/master/executive-committee-member"
                  exact
                  component={ExecutiveMaster}
                />
                <Route
                  path="/master/subscriptions"
                  exact
                  component={SubscriptionsMaster}
                />
                <Route
                  path="/miels/memberships"
                  exact
                  component={Memberships}
                />
                <Route
                  path="/miels/memberships/online-application"
                  exact
                  component={OnlineApplication}
                />
              </Switch>
            </Suspense>
          </NavbarContainer>
        </div>
      </Router>
    </React.Fragment>
  );
};

export default App;
