export default (state = null, action) => {
  switch (action.type) {
    case "FETCH_EVENTPOSTS":
      return action.payload;
    case "CLEAR_EVENTPOSTS":
      return null;
    default:
      return state;
  }
};
