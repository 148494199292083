import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Menu as MenuIcon,
  ArrowDropDown,
  ExpandLess,
  ExpandMore
} from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Button,
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  Slide,
  useScrollTrigger,
  Container,
  MenuItem,
  ClickAwayListener,
  Paper,
  MenuList,
  Collapse,
  Link as Links
} from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "teal"
    }
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    color: "white"
  },
  topNav: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  toolbar: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 65
    },
    marginTop: 145
  },
  topTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      color: "white"
    }
  },

  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: 20,
    padding: theme.spacing(1, 0)
  }
}));

const Navbar = props => {
  const { container, children, window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [openMiels, setOpenMiels] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openAboutMoblie, setOpenAboutMoblie] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <List>
        <ListItem
          button
          component={Link}
          to="/"
          onClick={() => setMobileOpen(false)}
        >
          <Typography align="center">
            <img
              src="/logo512.png"
              style={{ width: 60, marginLeft: 60 }}
              alt="MIELS"
            />
          </Typography>
        </ListItem>
        <Typography
          align="center"
          style={{ fontWeight: "bold", color: "#032F2E" }}
        >
          An Annual Peer Reviewed Journal
        </Typography>
        <Typography align="center" style={{ fontWeight: "bold" }}>
          ISSN: 2348 - 8611
        </Typography>

        <Divider />
        <ListItem
          button
          component={Link}
          to="/"
          onClick={() => setMobileOpen(false)}
        >
          <ListItemText primary="Home" />
        </ListItem>
        <Divider />
        <ListItem
          button
          component={Link}
          to="/online-submission"
          onClick={() => setMobileOpen(false)}
        >
          <ListItemText primary="Online Submission" />
        </ListItem>
        <Divider />
        <ListItem
          button
          component={Link}
          to="/archive"
          onClick={() => setMobileOpen(false)}
        >
          <ListItemText primary="Archive" />
        </ListItem>
        <Divider />
        <ListItem
          button
          component={Link}
          to="/scope"
          onClick={() => setMobileOpen(false)}
        >
          <ListItemText primary="Scope" />
        </ListItem>
        <Divider />
        <ListItem
          button
          component={Link}
          to="/editorial-board"
          onClick={() => setMobileOpen(false)}
        >
          <ListItemText primary="Editorial Board" />
        </ListItem>
        <Divider />
        <ListItem
          button
          component={Link}
          to="/advisory-board"
          onClick={() => setMobileOpen(false)}
        >
          <ListItemText primary="Advisory Board" />
        </ListItem>
        <Divider />
        <ListItem
          button
          component={Link}
          to="/author-guidelines"
          onClick={() => setMobileOpen(false)}
        >
          <ListItemText primary="Author Guidelines" />
        </ListItem>
        <Divider />
        <ListItem
          button
          component={Link}
          to="/subscriptions"
          onClick={() => setMobileOpen(false)}
        >
          <ListItemText primary="Subscriptions" />
        </ListItem>
        <Divider />
        <ListItem
          button
          component={Link}
          to="/contact-us"
          onClick={() => setMobileOpen(false)}
        >
          <ListItemText primary="Contact Us" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => setOpenAboutMoblie(!openAboutMoblie)}>
          <ListItemText primary="MIELS" />
          {openAboutMoblie ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openAboutMoblie} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              component={Link}
              to="/miels/about"
              style={{ paddingLeft: 30 }}
            >
              <ListItemText primary="About MIELS" />
            </ListItem>
            <Divider />
            <ListItem
              button
              component={Link}
              to="/miels/memberships"
              onClick={() => setMobileOpen(false)}
              style={{ paddingLeft: 30 }}
            >
              <ListItemText primary="Memberships" />
            </ListItem>
            <Divider />
            <ListItem
              onClick={() => setMobileOpen(false)}
              button
              component={Link}
              to="/miels/executive-members"
              style={{ paddingLeft: 30 }}
            >
              <ListItemText primary="Executive Committee Members" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => setMobileOpen(false)}
              component={Link}
              to="/miels/litfest"
              style={{ paddingLeft: 30 }}
            >
              <ListItemText primary="LitFEST" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => setMobileOpen(false)}
              component={Link}
              to="/miels/events"
              style={{ paddingLeft: 30 }}
            >
              <ListItemText primary="Events" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => setMobileOpen(false)}
              component={Link}
              to="/miels/contact"
              style={{ paddingLeft: 30 }}
            >
              <ListItemText primary="Contact MIELS" />
            </ListItem>
          </List>
        </Collapse>

        <Divider />
      </List>
    </div>
  );
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar position="fixed" className={classes.appBar} color="inherit">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              size="medium"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.topNav}>
              <Link to="/">
                <img
                  src="/logo512.png"
                  style={{ width: 60, padding: 5 }}
                  alt="MIELS"
                />
              </Link>
            </Typography>
            <Typography
              style={{
                fontFamily: "Berlin Sans FB",
                marginLeft: 5
              }}
              variant="h3"
              className={classes.topTitle}
            >
              Journal of MIELS
            </Typography>
            <Typography
              variant="h6"
              style={{
                flexGrow: 1,
                fontFamily: "Berlin Sans FB",
                marginLeft: 30,
                color: "#032F2E"
              }}
              className={classes.topNav}
            >
              An Annual Peer Reviewed Journal
            </Typography>
            <Typography
              style={{
                fontWeight: "bolder",
                marginLeft: 10,
                color: "#032F2E"
              }}
              variant="h6"
              className={classes.topNav}
            >
              ISSN: 2348 - 8611
            </Typography>
          </Toolbar>
          <Toolbar
            className={classes.topNav}
            style={{ backgroundColor: "teal" }}
            disableGutters
            variant="dense"
          >
            <Button
              color="inherit"
              component={Link}
              to="/"
              size="small"
              style={{ fontWeight: "bold", color: "white" }}
            >
              Home
            </Button>
            &nbsp;
            <Button
              color="inherit"
              component={Link}
              to="/online-submission"
              size="small"
              style={{ fontWeight: "bold", color: "white" }}
            >
              Online Submission
            </Button>
            &nbsp;
            <Button
              color="inherit"
              component={Link}
              to="/archive"
              size="small"
              style={{ fontWeight: "bold", color: "white" }}
            >
              Archive
            </Button>
            &nbsp;
            <Button
              color="inherit"
              component={Link}
              to="/scope"
              size="small"
              style={{ fontWeight: "bold", color: "white" }}
            >
              Scope
            </Button>
            &nbsp;
            <Button
              color="inherit"
              component={Link}
              to="/editorial-board"
              size="small"
              style={{ fontWeight: "bold", color: "white" }}
            >
              Editorial Board
            </Button>
            &nbsp;
            <Button
              color="inherit"
              component={Link}
              to="/advisory-board"
              size="small"
              style={{ fontWeight: "bold", color: "white" }}
            >
              Advisory Board
            </Button>
            &nbsp;
            <Button
              color="inherit"
              component={Link}
              to="/author-guidelines"
              size="small"
              style={{ fontWeight: "bold", color: "white" }}
            >
              AUTHOR GUIDELINES
            </Button>
            &nbsp;
            <Button
              color="inherit"
              component={Link}
              to="/subscriptions"
              size="small"
              style={{ fontWeight: "bold", color: "white" }}
            >
              Subscriptions
            </Button>
            &nbsp;
            <Button
              color="inherit"
              component={Link}
              to="/contact-us"
              size="small"
              style={{ fontWeight: "bold", color: "white" }}
            >
              Contact Us
            </Button>
            &nbsp;
            <ClickAwayListener onClickAway={() => setOpenMiels(false)}>
              <div>
                <Button
                  onClick={() => setOpenMiels(true)}
                  color="inherit"
                  size="small"
                  style={{ fontWeight: "bold", color: "white" }}
                >
                  MIELS <ArrowDropDown />
                </Button>
                {openMiels ? (
                  <Paper style={{ position: "absolute" }}>
                    <MenuList>
                      <Divider />
                      <MenuItem
                        onClick={() => setOpenMiels(false)}
                        dense
                        component={Link}
                        to="/miels/about"
                      >
                        About MIELS
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        dense
                        onClick={() => setOpenMiels(false)}
                        component={Link}
                        to="/miels/memberships"
                      >
                        Memberships
                      </MenuItem>
                      <Divider />

                      <MenuItem
                        dense
                        component={Link}
                        to="/miels/executive-members"
                        onClick={() => setOpenMiels(false)}
                      >
                        Executive Committee Members
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        dense
                        component={Link}
                        to="/miels/litfest"
                        onClick={() => setOpenMiels(false)}
                      >
                        LitFEST
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        dense
                        component={Link}
                        to="/miels/events"
                        onClick={() => setOpenMiels(false)}
                      >
                        Events
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        dense
                        component={Link}
                        to="/miels/contact"
                        onClick={() => setOpenMiels(false)}
                      >
                        Contact MIELS
                      </MenuItem>
                    </MenuList>
                  </Paper>
                ) : null}
              </div>
            </ClickAwayListener>
          </Toolbar>
        </AppBar>
      </Slide>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
        <footer className={classes.footer}>
          <Container maxWidth="lg">
            <Typography variant="h6" align="center">
              Also visit us on Facebook
            </Typography>
            <Typography variant="h6" align="center" gutterBottom>
              <Links
                target="_blank"
                href="https://www.facebook.com/mizoramenglishliterarysociety/"
              >
                <img src="/social.png" alt="MIELS" style={{ width: 60 }} />
              </Links>
            </Typography>

            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              MIELS {new Date().getFullYear()}
              {". Developed by HereUs "}
            </Typography>
          </Container>
        </footer>
      </main>
    </div>
  );
};

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  )
};

export default Navbar;
