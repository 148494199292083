export default (state = null, action) => {
  switch (action.type) {
    case "FETCH_MEMBERSHIPS":
      return action.payload;
    case "CLEAR_MEMBERSHIP":
      return null;
    default:
      return state;
  }
};
